import React, {useEffect, useState} from 'react';

import {
    Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, styled,
} from "@mui/material";
import {t} from "i18next";

import {Link} from "react-router-dom";
import {getSupportUsers} from "../../../api/getContentsApi/getSupportUsers";
import {StyledSearchInput} from "../../../components/StyledSearchInput/StyledSearchInput";

const SupportUserTable = () => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getSupportUsers(searchQuery); // Передаем query параметр в запрос
                setUsers(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [searchQuery]);

    // Пагинация данных
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        padding: '7px',
    }));

    return (
        <Paper sx={{width: '100%'}}>
            <StyledSearchInput
                variant="outlined"
                label={t("Search users")}
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{width: '100%', marginBottom: '10px','& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                        top: "10px",
                        color: '#2196f3',
                        fontSize: 14,
                    },}}
            />
            { loading? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                    <CircularProgress/>
                </Box>:
                <>
                    <TableContainer sx={{maxHeight: "60vh"}}>
                        <Table sx={{maxWidth: 370}} aria-label="users table" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{width: 100}}>{t("User name")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                    <TableRow key={user.id}>
                                        <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                            <Link to={`/owner/support/user/${user.id}`}>
                                                {user.username}
                                            </Link>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5,10, 25, 50, 100]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
        </Paper>
    );
};

export default SupportUserTable;
