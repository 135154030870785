import React, { useEffect, useState } from 'react';
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getOwnerCategoryInfo } from "../../api/getContentsApi/getOwnerCategoryInfo";
import styles from "./DiscoverySettingItem.module.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import IconImage from "../../components/Icons/IconImage";
import * as Yup from "yup";
import IconBack from "../../components/Icons/IconBack";
import { addOwnerCategory } from "../../api/addContent/addOwnerCategory";
import { deleteOwnerCategory } from "../../api/addContent/deleteOwnerCategory";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    styled
} from "@mui/material";
import EditIcon from "../../components/Icons/EditIcon";
import {getOwnerSliderInfo} from "../../api/getContentsApi/getOwnerSliderInfo";
import {addOwnerSlide} from "../../api/addContent/addOwnerSlide";
import {deleteOwnerSlice} from "../../api/addContent/deleteOwnerSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 20px !important;
    }
`;

const DiscoverySettingItem = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [topText, setTopText] = useState('');
    const [displayOrder, setDisplayOrder] = useState(1);
    const [bottomText, setBottomText] = useState('');
    const [image, setImage] = useState(null);
    const [sliderId, setSliderId] = useState(id || null);
    const [imagePreview, setImagePreview] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openSaveConfirm, setOpenSaveConfirm] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [errorMessages, setErrorMessages] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                const data = await getOwnerSliderInfo(id);
                setTopText(data.top_text);
                setSliderId(data.id);
                setDisplayOrder(data.display_order);
                setBottomText(data.bottom_text);
                setImage(data.image);
            }
            return () => {
                setErrorMessages(null);
            };
        };
        fetchData();
    }, [id]);

    const validationSchema = Yup.object().shape({
        topText: Yup.lazy(() =>
            id
                ? Yup.string().nullable()
                : Yup.string().required(t("Required field  top Text"))
        ),
        displayOrder: Yup.lazy(() =>
            id
                ? Yup.number().nullable()
                : Yup.number().required(t("Required field display Order"))
        ),
        bottomText: Yup.lazy(() =>
            id
                ? Yup.string().nullable()
                : Yup.string().required(t("Required field bottom Text"))
        ),
        image: Yup.lazy(() =>
            id
                ? Yup.mixed().nullable()
                : Yup.mixed()
                    .required(t("Please select an image"))
                    .test('fileSize', t("File size is too large, maximum 10MB allowed"), (value) => {
                        return !value || value.size <= 10485760;
                    })
                    .test('fileType', t("File must be an image"), (value) => {
                        return !value || value.type.startsWith('image/');
                    })
        ),
    });

    const handleDelete = async () => {
        if(id) {
            await deleteOwnerSlice(id);
            navigate("/owner/shops", { replace: true, state: { selectedTab: 2 } });
            setOpenDelete(false);
        }
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setErrorMessages(null);
    }

    const handleCloseSaveConfirm = () => {
        setOpenSaveConfirm(false);
        setErrorMessages(null);
    }

    const initialValues = {
        topText: topText || '',
        image: null,
    };

    const onSubmit = (values) => {
        setFormValues(values);
        setOpenSaveConfirm(true);
    };

    const handleConfirmSubmit = async () => {

        if (formValues) {
            const formData = new FormData();
            if (formValues.image) {
                formData.append('image', formValues.image);
                formData.append('AltText', formValues.image.name);
            }
            if (formValues.topText) {
                formData.append('top_text', formValues.topText);
            }
            if (formValues.displayOrder) {
                formData.append('display_order', formValues.displayOrder);
            }
            if (formValues.bottomText) {
                formData.append('bottom_text', formValues.bottomText);
            }

            try{
                await addOwnerSlide(formData, id);
                navigate("/owner/shops", { replace: true, state: { selectedTab: 3 } });
                setOpenSaveConfirm(false);
            }
            catch (error) {
                if (error.response && error.response.data) {
                    setErrorMessages(error.response.data.errors || ["An unexpected error occurred."]);
                } else {
                    setErrorMessages(["An unexpected error occurred."]);
                }
            }

        }
    };

    const handleFileChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        setFieldValue('image', file);
        setImagePreview(URL.createObjectURL(file));
    };

    const handleBack = () => {
        navigate("/owner/shops", { replace: true, state: { selectedTab: 3 } });
    };

    return (
        <div style={{ padding: "10px" }}>
            <div onClick={handleBack}>
                <IconBack size={20} pure={true} disabled fill={"#009de0"} marg={0}/>
                <h3 style={{ textAlign: "center", position: "relative", top: "-30px" }}>{t("Slide management")}</h3>
            </div>
            <div className={styles.mainBlock}>
                <h4>{t('id')}: {sliderId || t('New')}</h4>
                <Formik
                    enableReinitialize
                    initialValues={{ ...initialValues, topText, displayOrder, bottomText }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ setFieldValue }) => (
                        <Form className={styles.form}>
                            <div style={{position: "relative"}} className={styles.mainBlockOne}>
                                <label htmlFor="topText" className={styles.mainBlockLabel}>
                                    {t("Top text")}
                                </label>
                                <Field
                                    type="text"
                                    id="topText"
                                    name="topText"
                                    className={styles.mainBlockDownInput}
                                />
                                <div style={{position: "absolute", top: "10px", right: "10px"}}>
                                    <EditIcon/>
                                </div>

                                <ErrorMessage name="topText" component="div" className={styles.error}/>
                            </div>

                            <div style={{position: "relative"}} className={styles.mainBlockOne}>
                                <label htmlFor="displayOrder" className={styles.mainBlockLabel}>
                                    {t("display Order")}
                                </label>
                                <Field
                                    type="number"
                                    id="displayOrder"
                                    name="displayOrder"
                                    className={styles.mainBlockDownInput}
                                />
                                <div style={{position: "absolute", top: "10px", right: "10px"}}>
                                    <EditIcon/>
                                </div>

                                <ErrorMessage name="displayOrder" component="div" className={styles.error}/>
                            </div>

                            <div style={{position: "relative"}} className={styles.mainBlockOne}>
                                <label htmlFor="bottomText" className={styles.mainBlockLabel}>
                                    {t("Bottom text")}
                                </label>
                                <Field
                                    type="text"
                                    id="bottomText"
                                    name="bottomText"
                                    className={styles.mainBlockDownInput}
                                />
                                <div style={{position: "absolute", top: "10px", right: "10px"}}>
                                    <EditIcon/>
                                </div>

                                <ErrorMessage name="bottomText" component="div" className={styles.error}/>
                            </div>

                            <div className={styles.mainBlockDownImage}>
                                <label htmlFor="image" className={styles.mainBlockLabel}>
                                    {t("Image for slide")}
                                </label>
                                {(image || imagePreview) ? "" : <IconImage/>}
                                {(image || imagePreview) && (
                                    <img className={styles.preview}
                                         src={imagePreview || process.env.REACT_APP_MEDIA_URL + image}
                                         alt={t("Preview of the selected image")}/>
                                )}
                                <div className={styles.fileInputWrapper}>
                                    <input
                                        type="file"
                                        id="image"
                                        name="image"
                                        className={styles.fileInput}
                                        onChange={(e) => handleFileChange(e, setFieldValue)}
                                    />
                                    <span
                                        className={styles.fileInputLabel}>{id ? t("Edit image") : t("Select image")}</span>
                                </div>
                                <ErrorMessage name="image" component="div" className={styles.error}/>
                            </div>

                            <div className={styles["button-container"]}>
                                <button type="submit" className={styles["add-button"]}>
                                    {id ? t("Edit slide") : t("Save slide")}
                                </button>
                                {id && <button type="button" onClick={() => setOpenDelete(true)}
                                               className={styles["removeButton"]}>
                                    {t("Delete")}
                                </button>}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>

            <StyledDialog
                open={openDelete}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDelete}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("Are you sure you want to delete slide?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>{t("Cancel")}</Button>
                    <Button onClick={handleDelete}>{t("Delete")}</Button>
                </DialogActions>
            </StyledDialog>

            <StyledDialog
                open={openSaveConfirm}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {id ? t("Do you want to edit the slide?") : t("Do you want to create the new slide?")}
                    </DialogContentText>
                    {errorMessages && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                            {Object.entries(errorMessages ).map(([key, value]) => (
                                <div key={key}>{value}</div>
                            ))}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSaveConfirm}>{t("Cancel")}</Button>
                    <Button onClick={handleConfirmSubmit}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>
        </div>
    );
};

export default DiscoverySettingItem;
