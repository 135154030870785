import React, {useState} from 'react';
import styles from "./LikeButton.module.scss"

const LikeButton = () => {
    const [isLiked, setIsLiked] = useState(false);

    return (
        <button className={`${styles.mainConteiner} ${isLiked && styles.liked}`} tabIndex="0" onClick={() => setIsLiked(!isLiked)}>
            <div className={styles.upBlock}>
                <svg viewBox="0 0 24 24" className={styles.upBlockImg}>
                    <path
                        d="M23.305 5.07498C22.3508 3.21819 20.5724 1.92407 18.5121 1.58723C16.4518 1.25039 14.3539 1.91076 12.858 3.36698L12 4.14798L11.172 3.39398C9.67891 1.90936 7.56117 1.23646 5.48499 1.58698C3.42071 1.90968 1.63893 3.2085 0.699989 5.07498C-0.569125 7.56204 -0.0794272 10.5848 1.90999 12.544L11.283 22.2C11.4713 22.3936 11.7299 22.5029 12 22.5029C12.2701 22.5029 12.5287 22.3936 12.717 22.2L22.076 12.562C24.0755 10.6019 24.5729 7.57146 23.305 5.07498ZM20.657 11.151L12.357 19.696C12.2628 19.7928 12.1335 19.8474 11.9985 19.8474C11.8634 19.8474 11.7341 19.7928 11.64 19.696L3.32699 11.136C1.94998 9.78618 1.60717 7.69937 2.47999 5.97998C3.13326 4.68428 4.37197 3.78375 5.80599 3.56198C7.26664 3.31621 8.75572 3.79456 9.79999 4.84498L11.33 6.24498C11.7117 6.59273 12.2953 6.59273 12.677 6.24498L14.238 4.82198C15.278 3.7873 16.7534 3.3181 18.2 3.56198C19.6323 3.78494 20.869 4.68536 21.521 5.97998C22.3943 7.7072 22.0444 9.8015 20.657 11.151Z"></path>
                </svg>
            </div>
            <div className={styles.downBlock}>
                <svg viewBox="0 0 24 24" className={styles.downBlockImg}>
                    <path
                        d="M23.3 5.07598C22.3464 3.21891 20.5683 1.92433 18.5082 1.58709C16.448 1.24984 14.35 1.90991 12.854 3.36598L12 4.14698L11.173 3.39398C9.67977 1.90896 7.56138 1.23635 5.485 1.58798C3.42066 1.90983 1.63867 3.20844 0.699996 5.07498C-0.569117 7.56204 -0.0794196 10.5848 1.91 12.544L11.283 22.2C11.4713 22.3936 11.7299 22.5029 12 22.5029C12.2701 22.5029 12.5287 22.3936 12.717 22.2L22.077 12.562C24.0746 10.6009 24.5696 7.57083 23.3 5.07598Z"></path>
                </svg>
            </div>
        </button>
    );
};

export default LikeButton;