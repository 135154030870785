import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import IconBack from "../Icons/IconBack";
import Box from "@mui/material/Box";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    styled, TextField
} from "@mui/material";
import {t} from "i18next";

import styles from "./EditModerator.module.scss";

import {unVerifitateModerator} from "../../api/addContent/unVerifitateModerator";
import {getModeratorInfo} from "../../api/getContentsApi/getModeratorInfo";

import EditIcon from "../Icons/EditIcon";
import {updateModeratorInfo} from "../../api/addContent/updateModeratorInfo";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditModerator = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isOpenModel, setIsOpenModel] = useState("");
    const [editField, setEditField] = useState("");
    const [newValue, setNewValue] = useState("");
    const [errorMessages, setErrorMessages] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getModeratorInfo(id);
                setData(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [id, refresh]); // Add refresh to the dependency array

    const handleBack = () => {
        navigate(-1)
    }

    const handleCloseConfirm = () => {
        setOpen(false);
    };

    const handleUnverifiUser = async () => {
        await unVerifitateModerator(data?.id)
        setRefresh(prevState => !prevState);
        handleCloseConfirm()
        navigate(-1)
        setEditField("");
        setNewValue("");
    }
    const handleClose = (e) => {
        if (e) {
            if (e === isOpenModel) {
            } else {
                setIsOpenModel(e)
            }
        } else {
            setIsOpenModel(e)
        }
        setEditField("");
        setNewValue("");
        setErrorMessages([]);
    }

    const openEditModal = (field) => {
        setEditField(field);
        setNewValue(data.platform_settings[field] || "");
    };

    const handleSave = async () => {
        try {
            const updatedData = {  [editField]: newValue  };

            await updateModeratorInfo(id, updatedData);
            setRefresh((prev) => !prev);
            handleClose();
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessages(error.response.data.errors || ["An unexpected error occurred."]);
            } else {
                setErrorMessages(["An unexpected error occurred."]);
            }
        } finally {

        }
    };
    return (
        <div style={{padding: "10px 20px"}}>
            <div onClick={handleBack}>
                <IconBack size={20} pure={true} disabled fill={"#009de0"} marg={0}/>
                <h3 style={{textAlign: "center", position: "relative", top: "-30px"}}>{t("Moderator info")}</h3>
            </div>
            <div>
                {loading || !data ?
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                        <CircularProgress/>
                    </Box>
                    : <div>
                        <p>ID: {data?.id ?? ""}</p>
                        <p>{t("User name")}: {data?.username ?? ""}</p>
                        <p>{t("Email")}: {data?.email}</p>
                        <p>{t("Active")}: {data?.platform_settings?.is_active ? t("Yes") : t("No")}</p>
                        <p>{t("Is verified")}: {data?.is_verified ? t("Yes") : t("No")}</p>

                        <div style={{border: "1px solid black", padding: "10px", marginTop: "10px", borderRadius: "10px"}}>
                            <p>{t("Subscription cost")}: {data.platform_settings.seller_deposit} $
                                <Button onClick={() => openEditModal("seller_deposit")}><EditIcon/></Button>
                            </p>
                            <p>{t("Subscription expiration date")}: {data.platform_settings.subscription_expiry}
                                <Button onClick={() => openEditModal("subscription_expiry")}><EditIcon/></Button>
                            </p>
                            <p>{t("Percentage of purchases")}: {data.platform_settings.transaction_commission} %
                                <Button onClick={() => openEditModal("transaction_commission")}><EditIcon/></Button>
                            </p>
                        </div>
                    </div>
                }
                <div className={styles["button-container"]}>

                    <button className={styles["removeButton"]}
                            onClick={() => {
                                setOpen(true)
                                setIsOpenModel("2")
                            }}>
                        <span
                            className={styles["removeButton--element"]}>
                            {t("Unverified moderator")}
                        </span>

                    </button>
                </div>
            </div>
            <StyledDialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("Are you sure you want to demote this moderator?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>{t("Cancel")}</Button>
                    <Button onClick={handleUnverifiUser}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>
            <StyledDialog open={!!editField} onClose={handleClose}>
                <DialogTitle>{t("Edit Field")}</DialogTitle>
                <DialogContent>
                    <TextField
                        label={t("New Value")}
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    {errorMessages && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                            {Object.entries(errorMessages ).map(([key, value]) => (
                                <div key={key}>{value}</div>
                            ))}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("Cancel")}</Button>
                    <Button onClick={handleSave}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>

        </div>
    );
};

export default EditModerator;