import React from 'react';
import {Link} from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDown from "../static/ArrowDown";
import AccordionActions from "@mui/material/AccordionActions";
import './AccordionItem.scss';

const AccordionItem = ({item}) => {
    return (
        <Accordion  disableSpacing >
            <AccordionSummary
                disableSpacing
                expandIcon={<ArrowDown/>}
            >
                {item.title}
            </AccordionSummary>
            {item.subtitles.map((subtitle, i) => (
                    <AccordionActions  key={subtitle.subtitle} >
                        <Link className='accordion-link' style={{color: '#fff'}} to={subtitle.link}>{subtitle.subtitle}</Link>
                    </AccordionActions>
            ))}
        </Accordion>
    );
};

export default AccordionItem;