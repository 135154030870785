import React, {useState} from 'react';
import {t} from "i18next";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {styled} from "@mui/material";
import AllUsersTable from "../../components/AllUsersTable/AllUsersTable";
import SupportUserTable from "./SupportUserTable/SupportUserTable";
import styles from "./SupportUserTable/SupportUserTable.module.scss";
import SearchUser from "../../components/SearchUser/SearchUser";
import {StyledTabs} from "../../components/StyledTabs/StaledTabs";
import {CustomTabPanel} from "../../components/CustomTabPanel/CusmomTabPanel";
import {a11yProps} from "../../helpers/a11yProps";

const OwnerSupportPage = () => {
    const [value, setValue] = React.useState(0);
    const [openSearchUser, setOpenSearchUser] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleOpenModel = () => {
        setOpenSearchUser(true)
    }

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.titleBlock}>{t('Support')}</h1>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={t("Supports")} {...a11yProps(0)} />
                        <Tab label={t("All Users")} {...a11yProps(1)} />
                    </StyledTabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <SupportUserTable/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <AllUsersTable/>
                </CustomTabPanel>
            </Box>
            {
                openSearchUser ?
                    (
                        <SearchUser setIsOpen={setOpenSearchUser} isOpen={openSearchUser}/>
                    )
                    :
                    <></>
            }
        </div>
    );
};

export default OwnerSupportPage;