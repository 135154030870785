import React from 'react';
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import styles from "./ModeratorSettings.module.scss"
import CreateNewShop from "../CreateNewShopModal/CreateNewShop";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../TabPanel/TabPanel";
import useIsMobile from "../../helpers/useIsMobile";
import IconShop from "../Icons/IconShop";
import {t} from "i18next";

const ModeratorSetting = () => {
    const shops = rootStore.superUserStore.superUserShopsList;
    const { setIfOpenCreateShop,getIsOpenSettingShop,getIsOpenCreateShop } = rootStore.moderatorPanelStore;
    const [value, setValue] = React.useState(() => {
        const storedValue = localStorage.getItem('selectedTab');
        return storedValue ? parseInt(storedValue) : 0;
    });
    const setCurrentShopId = rootStore.superUserStore.setCurrentShopId;
    const setCurrentShopData = rootStore.superUserStore.setCurrentData;
    const isMobile = useIsMobile();
    const handleChangeOpen = () => {
        setIfOpenCreateShop(true);
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue.toString());
        localStorage.setItem('currentShop', JSON.stringify(shops[newValue]));
        setCurrentShopId(shops[newValue]?.ShopID);
        setCurrentShopData(shops[newValue]);
        // setShopData(shops[newValue]);
    };
    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    return (
        <>
            { getIsOpenSettingShop && getIsOpenCreateShop ?
                    (
                        <CreateNewShop />
                    ) :
                <div className={styles.mainConteiner}>
                    <div className={styles.mainBlock}>
                        <div className={styles.categoryImgBlock}>{t("Shop Settings")}
                            <div className={styles.createCategoryBlock}>
                                <button
                                    data-label="Create new Shop"
                                    id="createProductsGroup"
                                    className={styles.createCategoryBtn}
                                    onClick={handleChangeOpen}
                                >
                                <span className="dQaVw _8EVa2">
                                   <IconShop/>
                                </span>
                                    <span>{t("Create new Shop")}</span>
                                </button>
                            </div>
                        </div>
                        <div className={styles.fakeBlock}></div>
                        <div className={styles.bigCategoryBlock}>
                        </div>
                        <div className={styles.fakeBlock}></div>

                        <Box
                            sx={{
                                flexGrow: 1,
                                bgcolor: 'background.paper',
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row",
                                height: "100%"
                            }}
                        >
                            <Tabs
                                orientation={isMobile ? "horizontal" : "vertical"}
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{borderRight: 1, borderColor: 'divider', minWidth: isMobile ? 256 : 300}}
                                TabIndicatorProps={{
                                    style: {transition: 'none'}
                                }}
                            >
                                {shops.map((shop, index) => (
                                    <Tab label={shop.ShopName} {...a11yProps(shop.ShopID)} key={shop.ShopID}/>
                                ))}
                            </Tabs>
                            {shops.map((shop, index) => (
                                <TabPanel value={value} key={shop.ShopID} index={index} data={shop}/>
                            ))}
                        </Box>
                    </div>
                </div>
            }
        </>
    );
};

export default observer(ModeratorSetting);