import React, {useEffect, useState} from 'react';
import './FooterItem.scss';
import {Link} from "react-router-dom";
import AccordionItem from "../AccordionItem/AccordionItem";
import FooterBottom from "../FooterBottom/FooterBottom";

const FooterItem = ({ data }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const linkItems =
        <div className={"footer-item"}>
            <div className={"left-block"}>
                <p>WOLT</p>
            </div>
            {data.map((section, index) => (
                <div className={"footer-item__column"} key={index}>
                    <h2 className={"title"}>{section.title}</h2>
                    <>
                        {section.subtitles.map((subtitle, idx) => (
                            <div className={"footer-item__wrapper"} key={idx}>
                                <Link className={"link"} to={subtitle.link}>{subtitle.subtitle + ""}</Link>
                            </div>
                        ))}
                    </>
                </div>
            ))}
        </div>

    const DropdownList = () => {
        return (
            <div className={"footer-item__dropdown"}>
                <div className={"footer-item__column"} style={{marginBottom: "20px"}}>
                    <p>WOLT</p>
                </div>
                {data.map((item, index) => (
                    <div style={{marginBottom:"18px"}} key={index}>
                        <AccordionItem item={item}/>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="footer">
            {windowWidth < 850 ? (
                <DropdownList/>
            ) : (
                <div className="links">
                    {linkItems}
                </div>
            )}
            <FooterBottom/>
        </div>
    );
};

export default FooterItem;