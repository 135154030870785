import React, {useState} from 'react';
import Box from "@mui/material/Box";
import styles from "./TabPanel.module.scss"
import AcceptModal from "./AcceptModal/AcceptModal";
import rootStore from "../../store/RootStore";
import TextField from "@mui/material/TextField";
import {t} from "i18next";

function TabPanel(props) {
    const { children, value, index, couriers, ...other } = props;
    const [openAcceptD, setOpenAcceptD] = useState(false);
    const [openAcceptS, setOpenAcceptS] = useState(false);

    const {deleteShop, updateShop} = rootStore.shopStore;

    const [shopName, setShopName] = useState(props.data.ShopName);
    const [shopDescription, setShopDescription] = useState(props.data.Description);
    const [shopAddress, setShopAddress] = useState(props.data.Address);
    const [shopContact, setShopContact] = useState(props.data.Contact_description);
    const [shopDelivery, setShopDelivery] = useState(props.data.price_in_user_currency_delivery || props.data.Base_delivery_fee);


    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);

    const dataUpdate = {
        "ShopName": shopName,
        "Description": shopDescription,
        "Address": shopAddress,
        "Contact_description": shopContact,
        "Base_delivery_fee": shopDelivery,
    }
    const handleOff = () => {
        [setOpen1, setOpen2, setOpen3, setOpen4, setOpen5].forEach(e => e(false))
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={styles.conteiner}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3, overflowY: "scroll", height: "100vh"}}>
                    <div className={styles.mainConteiner}>
                        <div className={styles.infoBlock}>
                            <span className={styles.leftText}>{t("Name")}:</span>
                            {open1 ? (<TextField className={styles.inputFil} id="outlined-basic" variant="outlined" value={shopName} onChange={(e) => setShopName(e.target.value)}/>) : (<span className={styles.rightText}>{shopName}</span>)}
                            <button className={styles.btnEdit} onClick={() => setOpen1(!open1)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1"
                                     viewBox="0 0 306.637 306.637">
                                    <g>
                                        <g>
                                            <path
                                                d="M12.809,238.52L0,306.637l68.118-12.809l184.277-184.277l-55.309-55.309L12.809,238.52z M60.79,279.943l-41.992,7.896    l7.896-41.992L197.086,75.455l34.096,34.096L60.79,279.943z"/>
                                            <path
                                                d="M251.329,0l-41.507,41.507l55.308,55.308l41.507-41.507L251.329,0z M231.035,41.507l20.294-20.294l34.095,34.095    L265.13,75.602L231.035,41.507z"/>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.leftText}>{t("Description")}:</span>
                            {open2 ? (<TextField className={styles.inputFil} id="outlined-basic" variant="outlined" value={shopDescription} onChange={(e) => setShopDescription(e.target.value)}/>) : (<span className={styles.rightText}>{shopDescription}</span>)}
                            <button className={styles.btnEdit} onClick={() => setOpen2(!open2)}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1"
                                         viewBox="0 0 306.637 306.637">
                                        <g>
                                            <g>
                                                <path
                                                    d="M12.809,238.52L0,306.637l68.118-12.809l184.277-184.277l-55.309-55.309L12.809,238.52z M60.79,279.943l-41.992,7.896    l7.896-41.992L197.086,75.455l34.096,34.096L60.79,279.943z"/>
                                                <path
                                                    d="M251.329,0l-41.507,41.507l55.308,55.308l41.507-41.507L251.329,0z M231.035,41.507l20.294-20.294l34.095,34.095    L265.13,75.602L231.035,41.507z"/>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.leftText}>{t("Address")}:</span>
                            {open3 ? (<TextField className={styles.inputFil} id="outlined-basic" variant="outlined" value={shopAddress} onChange={(e) => setShopAddress(e.target.value)}/>) : (<span className={styles.rightText}>{shopAddress}</span>)}
                            <button className={styles.btnEdit} onClick={() => setOpen3(!open3)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                     fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1"
                                     viewBox="0 0 306.637 306.637">
                                    <g>
                                        <g>
                                            <path
                                                d="M12.809,238.52L0,306.637l68.118-12.809l184.277-184.277l-55.309-55.309L12.809,238.52z M60.79,279.943l-41.992,7.896    l7.896-41.992L197.086,75.455l34.096,34.096L60.79,279.943z"/>
                                            <path
                                                d="M251.329,0l-41.507,41.507l55.308,55.308l41.507-41.507L251.329,0z M231.035,41.507l20.294-20.294l34.095,34.095    L265.13,75.602L231.035,41.507z"/>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.leftText}>{t("Concat Description")}:</span>
                            {open4 ? (<TextField className={styles.inputFil} id="outlined-basic" variant="outlined" value={shopContact} onChange={(e) => setShopContact(e.target.value)}/>) : (<span className={styles.rightText}>{shopContact}</span>)}
                            <button className={styles.btnEdit} onClick={() => setOpen4(!open4)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1"
                                     viewBox="0 0 306.637 306.637">
                                    <g>
                                        <g>
                                            <path
                                                d="M12.809,238.52L0,306.637l68.118-12.809l184.277-184.277l-55.309-55.309L12.809,238.52z M60.79,279.943l-41.992,7.896    l7.896-41.992L197.086,75.455l34.096,34.096L60.79,279.943z"/>
                                            <path
                                                d="M251.329,0l-41.507,41.507l55.308,55.308l41.507-41.507L251.329,0z M231.035,41.507l20.294-20.294l34.095,34.095    L265.13,75.602L231.035,41.507z"/>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className={styles.infoBlock}>
                            <span className={styles.leftText}>{t("Delivery free")}:</span>
                            {open5 ? (<TextField className={styles.inputFil} id="outlined-basic" variant="outlined" value={shopDelivery} onChange={(e) => setShopDelivery(e.target.value)}/>) : (<span className={styles.rightText}>{shopDelivery}</span>)}
                            <button className={styles.btnEdit} onClick={() => setOpen5(!open5)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                     fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1"
                                     viewBox="0 0 306.637 306.637">
                                    <g>
                                        <g>
                                            <path
                                                d="M12.809,238.52L0,306.637l68.118-12.809l184.277-184.277l-55.309-55.309L12.809,238.52z M60.79,279.943l-41.992,7.896    l7.896-41.992L197.086,75.455l34.096,34.096L60.79,279.943z"/>
                                            <path
                                                d="M251.329,0l-41.507,41.507l55.308,55.308l41.507-41.507L251.329,0z M231.035,41.507l20.294-20.294l34.095,34.095    L265.13,75.602L231.035,41.507z"/>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className={styles.btnPanelBlock}>
                        <button className={styles.saveBtn} onClick={() => setOpenAcceptS(true)}>{t("Save")}</button>
                        <button className={styles.deleteBtn} onClick={() => setOpenAcceptD(true)}>{t("Delete")}</button>
                    </div>
                </Box>
            )}
            { openAcceptD && (<AcceptModal setState={setOpenAcceptD} shopId={props.data.ShopID} handleSubmit={deleteShop} action={true}/>) }
            { openAcceptS && (<AcceptModal setState={setOpenAcceptS} shopId={props.data.ShopID} handleSubmit={updateShop} action={false} data={dataUpdate} handleOff={handleOff}/>) }
        </div>
    );
}

export default TabPanel;