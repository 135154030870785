import React, { useState, useMemo } from "react";

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";

import { TextField } from "@mui/material";
import styles from "./OrderCardMain.module.scss";

import IconBack from "../../components/Icons/IconBack";
import rootStore from "../../store/RootStore";
import ItemsModal from "./ItemsModal/ItemsModal";
import SwipeButton from "../../components/SwipeButton/SwipeButton";
import AcceptModal from "../../components/AcceptModal/AcceptModal";
import AddressInfo from "./AddressInfo/AddressInfo";
import { getOrder } from "../../helpers";
import DeliveryTimes from "./DeliveryTimes/DeliveryTimes";
import PromoCodeModal from "./PromoCodeModal/PromoCodeModal";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import LocationAdress from "./LocationAdress/LocationAdress";
import { ORDER_STATUSES } from "../../constants";
import { getCurrencySymbol } from "../../helpers/getCurrency";
import IconCaretDownOutline from "../../components/Icons/IconCaretDownOutline";
import { IconCaretUpOutline } from "../../components/Icons/IconCaretUpOutline";
import {
  ChatIcon,
  ShopIcon,
  CashIcon,
  BalanceIcon,
  MapMarkIcon,
  InformationIcon,
} from "../../assets/icons";

const OrderCardMain = () => {
  const navigate = useNavigate();

  const [mobile, setMobile] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [placemark, setPlacemark] = useState([]);
  const [needPhone, setNeedPhone] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [isCheckout, setCheckout] = useState(false);
  const [openPromo, setOpenPromo] = useState(false);
  const [openItems, setOpenItems] = useState(false);
  const [isErrorCash, setErrorCash] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [costDelivery, setCostDelivery] = useState("");
  const [openDelivery, setOpenDelivery] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);

  const { confirmOrders, cancelModOrders } = rootStore.ordersModerStore;
  const { acceptOrder, finishOrder, cancelOrder } = rootStore.curierStore;
  const { lazyInitialize, addressData, locationData } = rootStore.checkoutStore;

  const isWorked = window.location.pathname.includes("worked");
  const isActual = window.location.pathname.includes("actual");
  const isModerator = window.location.pathname.includes("moderator");
  const isCurier = window.location.pathname.includes("curier-panel");

  const currentOrder = getOrder();
  const order = useMemo(() => JSON.parse(currentOrder), [currentOrder]);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  // TODO: неиспользуемая функция, закомментил
  // const handleClickLocation = () => {
  //     setOpenLocation(true);
  // };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // TODO: lazyInitialize, Вероятно лишний вызов /checkout-preview/, вызывает ошибку
  // useEffect(() => {
  //     lazyInitialize();
  // }, []);

  const handleSetCost = (event) => {
    setCostDelivery(event.replace(/[^\d.]/g, ""));
  };

  const handleSubmit = async () => {
    if (mobile) {
      const order = {
        address: `${locationData} ${addressData.buildingName} ${addressData.enterance} ${addressData.floor} ${addressData.Apartment}`,
        phone_number: mobile,
      };
    } else {
      setNeedPhone(true);
      setTimeout(() => {
        setNeedPhone(false);
      }, 2000);
    }
    if (isActual) {
      acceptOrder(order.id);
      setCheckout(true);
      setTimeout(() => {
        navigate("/curier-panel/worked");
      }, 500);
    }
    if (isWorked) {
      finishOrder(order.id);
      setCheckout(true);
      setTimeout(() => {
        navigate("/curier-panel/all");
      }, 500);
    }
    if (isModerator) {
      if (costDelivery && placemark) {
        confirmOrders(order.shop, order.id, placemark, costDelivery);
        navigate(-1);
      }
    }
  };

  const handleCancelSubmit = async () => {
    if (!isModerator) {
      cancelOrder(order.id);
      navigate("/curier-panel/all");
    } else {
      cancelModOrders(order.shop, order.id);
      navigate(-1);
    }
  };

  if (isCheckout && !isCurier) {
    setTimeout(() => {
      navigate("/discovery/me/order-history");
    }, 2000);
  }

  if (isErrorCash) {
    setTimeout(() => {
      setErrorCash(false);
    }, 2000);
  }

  const handleMapClick = (event) => {
    const coords = event.get("coords");
    setPlacemark(coords);
  };

  const time = order?.created_at?.split("T")[1];
  const dataAddress = JSON.parse(order?.delivery_address_text);
  const items = JSON.parse(order?.items);
  const currency = order?.currency;

  const handleCloseAcceptModal = () => {
    setOpenAcceptModal(false);
  };

  const handleOpenAcceptModal = () => {
    setOpenAcceptModal(true);
  };

  const geo = useMemo(() => placemark.length > 0, [placemark]);
  const cost = useMemo(() => costDelivery, [costDelivery]);

  const handleOpenChat = () => {
    setTimeout(() => {
      navigate(`/discovery/me/order-chat`)
    }, 250)
  };

  return (
    <>
      <div className={styles.mainPage}>
        <YMaps query={{ lang: 'en_US' }} height={"10000px"}>
          <div className={styles.mapBlock}>
            <div className={styles.backer}>
              <IconBack size={20} pure={true} fill={"#009de0"} marg={0} />
            </div>
            {/* <button 
                    aria-disabled="false"
                    type="button"
                    className={`${styles.footerBtnCancel} ${styles.error}`}
                >
                    <div className={styles.footerBtnMain} onClick={() => handleActive(false)}>Cancel</div>
                </button> */}
            <Map
              onClick={handleMapClick}
              defaultState={{
                center: [
                  order?.delivery_coordinates.latitude,
                  order?.delivery_coordinates.longitude,
                ],
                zoom: 15,
              }}
              options={{
                language: 'en'
              }}
              width={"100%"}
              height={"100vh"}
            >
              <Placemark
                properties={{ iconContent: "D" }}
                options={{ iconColor: "green" }}
                geometry={[
                  order?.delivery_coordinates.latitude,
                  order?.delivery_coordinates.longitude,
                ]}
              />
              {(placemark.length > 0 && isModerator && order.status === ORDER_STATUSES.storeConfirmations) ? (
                <Placemark
                  properties={{ iconContent: "P" }}
                  options={{ iconColor: "red" }}
                  geometry={placemark}
                />
              ) :
              (
                <Placemark
                  properties={{ iconContent: "P" }}
                  options={{ iconColor: "red" }}
                  geometry={[
                    order?.delivery_address_take?.latitude,
                    order?.delivery_address_take?.longitude
                  ]}
                />
              )}
            </Map>
          </div>
        </YMaps>
        <div
          className={`${styles.mainConteiner} ${isHidden ? styles.hidden : ""}`}
        >
          <div style={{ textAlign: "center" }} onClick={toggleVisibility}>
            <IconCaretDownOutline />
          </div>
          <div className={styles.shopNamspan}>
            <span className={styles["shopName__title"]}>
              {" "}
              {t("Delivery at")}{" "}
            </span>{" "}
            <span className={styles["shopName__description"]}>
              {" "}
              {order.shop_name}
            </span>
          </div>
          <div className={styles.mainBoxBlock}>
            <div className={styles.tabsContainer}>
              <div className={styles.tabs}>
                {" "}
                {/* Кнопки табов */}
                <button
                  className={`${styles.tabButton} ${
                    activeTab === 0 ? styles.active : ""
                  }`}
                  onClick={() => handleTabClick(0)}
                  data-testid="shopping-cart-tab"
                >
                  {" "}
                  {t("Information")}{" "}
                </button>
                <button
                  className={`${styles.tabButton} ${
                    activeTab === 1 ? styles.active : ""
                  }`}
                  onClick={() => handleTabClick(1)}
                  data-testid="order-again-tab"
                >
                  {" "}
                  {t("Products list")}{" "}
                </button>
              </div>
            </div>
          </div>
          {activeTab === 0 && (
            <div className={styles.conteinerInfo}>
              <div className={styles.adressRowBlock}>
                <MapMarkIcon stroke="#000000" />
                <div className={styles.rightContAdres}>
                  <span>{dataAddress?.country}</span>
                  <span>{dataAddress?.city}</span>
                </div>
              </div>
              <div className={styles.adressRowBlock}>
                <InformationIcon fill="#000000" stroke="#000000" />
                <div className={styles.rightContAdres}>
                  <span className={styles.noteTitle}>{t("Notes")}:</span>
                  <span>{order?.courier_comment}</span>
                </div>
              </div>
              <span className={styles.detailTitle}>{t("Order details")}:</span>
              <div className={styles.typePayBlock}>
                {order?.payment_type === "balance" ? (
                  <BalanceIcon fill={"#1C274C"} />
                ) : (
                  <CashIcon fill={"#0F0F0F"} />
                )}
                <div className={styles.rightContAdres}>
                  <span className={styles.topRowPay}>{t("Payment type")}:</span>
                  <span className={styles.downRowPay}>
                    {order?.payment_type}
                  </span>
                </div>
              </div>
              <div className={styles.typePayBlock2}>
                <ShopIcon />
                <div className={styles.rightContAdres}>
                  <span className={styles.topRowPay}>{t("From")}:</span>
                  <span className={styles.downRowPay}>{order?.shop_name}</span>
                </div>
              </div>
              {order.status === ORDER_STATUSES.storeConfirmations &&
                isModerator && (
                  <TextField
                    required
                    id="outlined-required"
                    label={t("Cost delivery for courier")}
                    defaultValue="0"
                    value={costDelivery}
                    onChange={(e) => handleSetCost(e.target.value)}
                    sx={{ marginTop: "1rem" }}
                  />
                )}
              <span className={styles.detailTitle}>
                {t("Problem with delivery?")}
              </span>
              <button className={styles.downBtnChat} onClick={handleOpenChat}>
                <ChatIcon />
                <span className={styles.titleBtnChat}>{t("Chat")}</span>
              </button>
            </div>
          )}
          {activeTab === 1 && (
            <div className={styles.mainTabFirstConteiner}>
              <span className={styles.titlePosition}>
                {t("Positions in order")}:
              </span>
              <div className={styles.bodyCarts}>
                {items.map((e) => (
                  <span className={styles.itemCard}>
                    {e.product.ProductName} x {e.product.quantity.split(".")[0]}{" "}
                    x {e.product.Price} {getCurrencySymbol()}
                  </span>
                ))}
              </div>
              <div className={styles.deliveryBlock}>
                <span className={styles.titlePosition}>
                  {t("Delivery status")}:
                </span>
                {
                  window.location.pathname.includes("moderatior/order/") ?
                      (
                          <div className={styles.statusConteiner}>
                            <span className={styles.timeDate}>
                              {time.split(":")[0]}:{time.split(":")[1]}
                            </span>{" "}
                            <span className={styles.roundBlock}></span>{" "}
                            <span className={styles.badgeStatus}>{order?.status}</span>
                          </div>
                      )
                      :
                      (order?.status_logs.length > 0 ?
                      (order?.status_logs.map((el, index) =>
                          <>
                            <div className={styles.statusConteiner}>
                              <span className={styles.timeDate}>
                                {el?.changed_at?.split("T")[1].split(":")[0]}:{el?.changed_at?.split("T")[1].split(":")[1]}
                              </span>{" "}
                              <span className={`${styles.roundBlock}  ${index === 0 && styles.secondCol} ${index === 1 && styles.firstCol} ${index === 2 && styles.lastCol}`}></span>{" "}
                              <span className={styles.badgeStatus}>{el?.new_status_display}</span>
                            </div>
                            {
                              order?.status_logs.length !== (index+1)
                                  ?
                                  (<div className={styles.statusConteiner}>
                                    <span className={styles.spaceRow}></span>
                                    <span className={styles.spesZnak}>|</span>
                                  </div>)
                              : (<></>)
                            }
                          </>
                          )
                      ):
                      (
                          <div className={styles.statusConteiner}>
                    <span className={styles.timeDate}>
                      {time.split(":")[0]}:{time.split(":")[1]}
                    </span>{" "}
                            <span className={styles.roundBlock}></span>{" "}
                            <span className={styles.badgeStatus}>{order?.status}</span>
                          </div>
                      )
                    )
                }
              </div>
            </div>
          )}
          {order.status === ORDER_STATUSES.storeConfirmations ||
          (isCurier &&
              (order.status === ORDER_STATUSES.confirmed ||
                  order.status === ORDER_STATUSES.taking)) ? (
              <div className={styles.swipeButton}>
                <SwipeButton
                    handlerAction={handleOpenAcceptModal}
                notNavigate
                modalIsOpen={openAcceptModal}
              />
            </div>
          ) : null}
        </div>
        <PromoCodeModal isOpen={openPromo} setIsOpen={setOpenPromo} />{" "}
        <PaymentMethods isOpen={openPayment} setIsOpen={setOpenPayment} />
        <LocationAdress
          isOpen={openLocation && !isCurier && !isModerator}
          setIsOpen={setOpenLocation}
        />
        <AddressInfo
          isOpen={openLocation && (isCurier || isModerator)}
          setIsOpen={setOpenLocation}
          dataAddress={dataAddress}
        />
        <DeliveryTimes isOpen={openDelivery} setIsOpen={setOpenDelivery} />
        <ItemsModal
          isOpen={openItems}
          setIsOpen={setOpenItems}
          items={items}
          currency={currency}
        />
        {openAcceptModal && (
          <AcceptModal
            action={openAcceptModal}
            geo={geo}
            cost={cost}
            handleClose={handleCloseAcceptModal}
            handleSubmit={handleSubmit}
            handleCancel={handleCancelSubmit}
          />
        )}
      </div>
      {isHidden && (
        <div
          style={{ position: "absolute", left: "7vw", top: "93vh" }}
          onClick={toggleVisibility}
        >
          <IconCaretUpOutline />
        </div>
      )}
    </>
  );
};
export default observer(OrderCardMain);
