import React from 'react';
import style from './FooterBottom.module.scss'
import ModalSwitchLanguage from "../ModalSwitchLanguage/ModalSwitchLanguage";
import {Link} from "react-router-dom";
import CompanyName from "./CompanyName/CompanyName";

const FooterBottom = () => {
    return (
        <div className={style.bottom}>
            <ModalSwitchLanguage/>
            <div className={style["right-side"]}>
                <Link className={style.link} to={"/"}>Accessibility Statement</Link>
                <Link className={style.link} to={"/"}>User Terms of Service</Link>
                <Link className={style.link} to={"/"}>Privacy Statement</Link>
                <CompanyName/>
            </div>

        </div>
    );
};

export default FooterBottom;