import React from 'react';
import './Footer.scss'

import FooterItem from "./FootreItem/FooterItem";

const CONTENT_FOOTER = [
    {
        "title": "Partner with Wolt",
        "subtitles": [
            {
                "subtitle": "For couriers",
                "link": "/discovery"
            },
            {
                "subtitle": "For merchants",
                "link": "/discovery/restaurants"
            },
            {
                "subtitle": "For affiliates",
                "link": "/discovery/stores"
            }
        ]
    },
    {
        "title": "Company",
        "subtitles": [
            {
                "subtitle": "About us",
                "link": "/about-us"
            },
            {
                "subtitle": "What we stand for",
                "link": "/me/personal-info"
            }
            ,
            {
                "subtitle": "Jobs",
                "link": "/"
            }
            ,
            {
                "subtitle": "Sustainability",
                "link": "/"
            }
            ,
            {
                "subtitle": "Security",
                "link": "/"
            }
            ,
            {
                "subtitle": "Investors",
                "link": "/"
            },
        ]
    },
    {
        "title": "Products",
        "subtitles": [
            {
                "subtitle": "Wolt Drive",
                "link": "/"
            },
            {
                "subtitle": "Wolt Market",
                "link": "/"
            },
            {
                "subtitle": "Wolt+",
                "link": "/"
            },
            {
                "subtitle": "Wolt for Work",
                "link": "/"
            },
            {
                "subtitle": "Wolt Ads",
                "link": "/"
            },
        ]
    },
    {
        "title": "Useful links",
        "subtitles": [
            {
                "subtitle": "Support",
                "link": "/"
            },
            {
                "subtitle": "Media",
                "link": "/"
            },
            {
                "subtitle": "Contact",
                "link": "/"
            },
            {
                "subtitle": "Speak up",
                "link": "/"
            },
            {
                "subtitle": "Promo codes",
                "link": "/"
            },
            {
                "subtitle": "Developers",
                "link": "/"
            },
        ]
    },
    {
        "title": "Follow us",
        "subtitles": [
            {
                "subtitle": "Blog",
                "link": "/"
            },
            {
                "subtitle": "Engineering Blog",
                "link": "/"
            },
            {
                "subtitle": "Instagram",
                "link": "/"
            },
            {
                "subtitle": "Facebook",
                "link": "/"
            },
            {
                "subtitle": "Twitter",
                "link": "/"
            },
            {
                "subtitle": "LinkedIn",
                "link": "/"
            },
            {
                "subtitle": "Wolt Life",
                "link": "/"
            },
        ]
    },
]

const Footer = () => {
    return (
        <div className={"footer"}>
            <FooterItem data={CONTENT_FOOTER}/>
        </div>
    );
};

export default Footer;