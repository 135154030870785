import React, {useEffect, useRef, useState} from 'react';
import styles from "./RestoranHeader.module.scss"
import LikeButton from "../Buttons/LikeButton/LikeButton";
import RestoranTags from "../RestoranTags/RestoranTags";
import InfoButton from "../Buttons/InfoButton/InfoButton";
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import ModalMoreInformation from "../../ModalMoreInformation/ModalMoreInformation";
import ModalLimitedInformation from "../../ModalLimitedInformation/ModalLimitedInformation";
import HeaderFantom from "../../HeaderFantom/HeaderFantom";
import ModalMobileMoreInforation from "../../ModalMobileMoreInforation/ModalMobileMoreInforation";
import IconSmile from "../../Icons/IconSmile";
import {useTranslation} from "react-i18next";
import {getCurrencySymbol} from "../../../helpers/getCurrency";

const Stores = () => {
    const {t} = useTranslation();
    const shop = rootStore.shopStore.shopData;
    const {setOpenMoreInfo} = rootStore.shopStore;
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenL, setIsOpenL] = useState(false);
    const mainRef = useRef(null);
    const symbol = getCurrencySymbol();
    const [positionElement, setPositionElement] = useState(window.innerHeight);
    let positionOp = 1 - (positionElement / -250);

    const isMobile = window.innerWidth < 641;

    useEffect(() => {
        const handleResize = () => {
            setPositionElement(mainRef.current.getBoundingClientRect().y);
        };
        window.addEventListener('scroll', handleResize);
        return () => {
            window.removeEventListener('scroll', handleResize);
        };
    }, []);


    const handleOpenMoreInfo = () => {
        setIsOpen(true)
        setOpenMoreInfo(true)
    }
    const handleOpenMoreInfoL = () => {
        setIsOpenL(true)
    }

    const {
        shop_images,
        ShopName,
        Description,
        Base_delivery_fee,
        price_in_user_currency_delivery,
        Small_order_surcharge_limit,
        Long_delivery_surcharge_limit,
        Estimated_delivery_time,
        Average_rating,
    } = shop;

    useEffect(() => {
        return rootStore.shopStore.clearShopData();
    }, []);
    if (!shop) {
        return <div>Loading</div>;
    }
    return (
        <div>
            {window.innerWidth < 640 && (
                positionOp > 0 ? <HeaderFantom shopName={ShopName}/> : <HeaderFantom parent={true} shopName={ShopName}/>
            )
            }
            {isOpen ? (window.innerWidth < 640 ? (<ModalMobileMoreInforation/>) : (
                <ModalMoreInformation isOpen={isOpen} openClose={setIsOpen}/>)) : ""}

            {isOpenL ? (<ModalLimitedInformation isOpen={isOpenL} openClose={setIsOpenL}/>) : ""}
            <div>
                <header className={styles.mainContainer} ref={mainRef} style={{opacity: positionOp}}>
                    <div className={styles.upBlock}>
                        <div className={styles.upBlockConteiner}>
                            <img loading="lazy" className={styles.upBlockImg} src={shop_images ?
                                process.env.REACT_APP_MEDIA_URL + shop_images[0]?.ImageUrl : ""}
                                 alt="Image shop"/>
                        </div>
                    </div>
                    <div className={styles.middleBlock}></div>
                    <div className={styles.downBlock}>
                        <div className={styles.downBlockConteiner}>
                            <h1 className={styles.downBlockTitle}>
                                {isMobile ? "" : <span className={styles.downBlockTitleText}>{ShopName}</span>}
                            </h1>
                            <div className={styles.downBlockDescription}>
                                {isMobile ? "" : <p className={styles.downBlockDescriptionText}>{Description}</p>}
                            </div>
                            <div className={styles.downBlockTagsBlock}>
                                <div className={styles.downBlockTagsContainerRow}>
                                    <div className={styles.downBlockTagsRow}>
                                        <RestoranTags text={`${t("Delivery")}: ${symbol} ${Estimated_delivery_time}`}/>
                                        <RestoranTags text={`${t("Min. order")}: ${symbol} ${Small_order_surcharge_limit}`}/>
                                        <RestoranTags text={`${t("Delivery in")} ${Long_delivery_surcharge_limit}`}/>
                                        <RestoranTags text={`${t("Service fee")} ${price_in_user_currency_delivery || Base_delivery_fee}$`}/>
                                    </div>
                                </div>
                                {isMobile ? "" : <LikeButton/>}
                            </div>
                        </div>
                    </div>
                </header>
                <div className={styles.infoConteiner}>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoRow}>
                            {isMobile ?
                                (
                                    <div className={styles.mobileInfoBlock}>
                                        <div className={styles.mobileTitleBlock}>
                                            <h1 className={styles.mobileTitle}>{ShopName}</h1>
                                            <LikeButton/>
                                        </div>
                                        <span className={styles.mobileDescription}>{Description}</span>
                                        <div className={styles.mobileBlockTaggleBlock}>
                                            <div className={styles.mobileBlockTaggleRow}>
                                                <IconSmile/>
                                                <div className={styles.mobileMiddleInfoBlock}>
                                                    <span
                                                        className={styles.mobileMiddleInfo}>{t("Excellent")} {Number(Average_rating).toFixed(2)}</span>
                                                </div>
                                            </div>
                                            <div className={styles.mobileBlockTaggleRow}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                                                     className={styles.svg3}
                                                     viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M5.06152 12C5.55362 8.05369 8.92001 5 12.9996 5C17.4179 5 20.9996 8.58172 20.9996 13C20.9996 17.4183 17.4179 21 12.9996 21H8M13 13V9M11 3H15M3 15H8M5 18H10"
                                                        stroke="#202125a3" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                </svg>
                                                <div className={styles.mobileMiddleInfoBlock}>
                                                    <span
                                                        className={styles.mobileMiddleInfo}>{t("Open")} ` {t("Closes at")} 19:15</span>
                                                    <button className={styles.mobileInfoBtn}
                                                            onClick={() => handleOpenMoreInfo()}>{t("More info")}</button>
                                                </div>
                                            </div>
                                            <div className={styles.mobileBlockTaggleRow}>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     height="24px" className={styles.svg}
                                                     width="24px" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                                    <style type="text/css">
                                                    </style>
                                                    <g>
                                                        <path className="st0"
                                                              d="M311.069,130.515c-0.963-5.641-5.851-9.768-11.578-9.768H35.43c-7.61,0-13.772,6.169-13.772,13.765   c0,7.61,6.162,13.772,13.772,13.772h64.263c7.61,0,13.772,6.17,13.772,13.773c0,7.603-6.162,13.772-13.772,13.772H13.772   C6.169,175.829,0,181.998,0,189.601c0,7.603,6.169,13.764,13.772,13.764h117.114c6.72,0,12.172,5.46,12.172,12.18   c0,6.72-5.452,12.172-12.172,12.172H68.665c-7.61,0-13.772,6.17-13.772,13.773c0,7.602,6.162,13.772,13.772,13.772h45.857   c6.726,0,12.179,5.452,12.179,12.172c0,6.719-5.453,12.172-12.179,12.172H51.215c-7.61,0-13.772,6.169-13.772,13.772   c0,7.603,6.162,13.772,13.772,13.772h87.014l5.488,31.042h31.52c-1.854,4.504-2.911,9.421-2.911,14.598   c0,21.245,17.218,38.464,38.464,38.464c21.237,0,38.456-17.219,38.456-38.464c0-5.177-1.057-10.094-2.911-14.598h100.04   L311.069,130.515z M227.342,352.789c0,9.146-7.407,16.553-16.553,16.553c-9.152,0-16.56-7.407-16.56-16.553   c0-6.364,3.627-11.824,8.892-14.598h15.329C223.714,340.965,227.342,346.424,227.342,352.789z"/>
                                                        <path className="st0"
                                                              d="M511.598,314.072l-15.799-77.941l-57.689-88.759H333.074l32.534,190.819h38.42   c-1.846,4.504-2.904,9.421-2.904,14.598c0,21.245,17.219,38.464,38.456,38.464c21.246,0,38.464-17.219,38.464-38.464   c0-5.177-1.057-10.094-2.91-14.598h16.741c6.039,0,11.759-2.708,15.582-7.386C511.273,326.136,512.8,319.988,511.598,314.072z    M392.529,182.882h26.314l34.162,52.547h-51.512L392.529,182.882z M456.14,352.789c0,9.146-7.407,16.553-16.56,16.553   c-9.138,0-16.552-7.407-16.552-16.553c0-6.364,3.635-11.824,8.892-14.598h15.329C452.513,340.965,456.14,346.424,456.14,352.789z"/>
                                                    </g>
                                                </svg>
                                                <div className={styles.mobileMiddleInfoBlock}>
                                                    <div className={styles.mobileMiddleMidInf}>
                                                        <span
                                                            className={styles.mobileMiddleInfo}>{t("Delivery")} in {Small_order_surcharge_limit}- {Long_delivery_surcharge_limit} min</span>
                                                        <span className={styles.mobileDownText}>Tel Aviv</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.mobileBlockTaggleRow}>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     height="24px" className={styles.svg}
                                                     width="24px" version="1.1" id="_x32_" viewBox="0 0 512 512">
                                                    <style type="text/css">
                                                    </style>
                                                    <g>
                                                        <path className="st0"
                                                              d="M157.604,321.598c7.26-2.232,10.041-6.696,10.6-10.046c-0.559-4.469-3.143-6.279-3.986-14.404   c-0.986-9.457,6.91-32.082,9.258-36.119c-0.32-0.772-0.65-1.454-0.965-2.247c-11.002-6.98-22.209-19.602-27.359-42.416   c-2.754-12.197-0.476-24.661,6.121-35.287c0,0-7.463-52.071,3.047-86.079c-9.818-4.726-20.51-3.93-35.164-2.466   c-11.246,1.126-12.842,3.516-21.48,2.263c-9.899-1.439-17.932-4.444-20.348-5.654c-1.392-0.694-14.449,10.89-18.084,20.35   c-11.531,29.967-8.435,50.512-5.5,66.057c-0.098,1.592-0.224,3.178-0.224,4.787l2.68,11.386c0.01,0.12,0,0.232,0.004,0.346   c-5.842,5.24-9.363,12.815-7.504,21.049c3.828,16.934,12.07,23.802,20.186,26.777c5.383,15.186,10.606,24.775,16.701,31.222   c1.541,7.027,2.902,16.57,1.916,26.032C83.389,336.78,0,315.904,0,385.481c0,9.112,25.951,23.978,88.818,28.259   c-0.184-1.342-0.31-2.695-0.31-4.078C88.508,347.268,129.068,330.379,157.604,321.598z"/>
                                                        <path className="st0"
                                                              d="M424.5,297.148c-0.986-9.457,0.371-18.995,1.912-26.011c6.106-6.458,11.328-16.052,16.713-31.246   c8.113-2.977,16.35-9.848,20.174-26.774c1.77-7.796-1.293-15.006-6.59-20.2c3.838-12.864,18.93-72.468-26.398-84.556   c-15.074-18.839-28.258-18.087-50.871-15.827c-11.246,1.126-12.844,3.516-21.477,2.263c-1.89-0.275-3.682-0.618-5.41-0.984   c1.658,2.26,3.238,4.596,4.637,7.092c15.131,27.033,11.135,61.27,6.381,82.182c5.67,10.21,7.525,21.944,4.963,33.285   c-5.15,22.8-16.352,35.419-27.348,42.4c-0.551,1.383-2.172,4.214,0.06,7.006c2.039,3.305,2.404,2.99,4.627,5.338   c1.539,7.027,2.898,16.57,1.91,26.032c-0.812,7.85-14.352,14.404-10.533,17.576c3.756,1.581,8.113,3.234,13,5.028   c28.025,10.29,74.928,27.516,74.928,89.91c0,1.342-0.117,2.659-0.291,3.96C486.524,409.195,512,394.511,512,385.481   C512,315.904,428.613,336.78,424.5,297.148z"/>
                                                        <path className="st0"
                                                              d="M301.004,307.957c-1.135-10.885,0.432-21.867,2.201-29.956c7.027-7.423,13.047-18.476,19.244-35.968   c9.34-3.427,18.826-11.335,23.23-30.826c2.028-8.976-1.494-17.276-7.586-23.256c4.412-14.81,21.785-83.437-30.398-97.353   c-17.354-21.692-32.539-20.825-58.57-18.222c-12.951,1.294-14.791,4.048-24.731,2.603c-11.4-1.657-20.646-5.117-23.428-6.508   c-1.602-0.803-16.637,12.538-20.826,23.428c-13.27,34.5-9.705,58.159-6.33,76.056c-0.111,1.833-0.264,3.658-0.264,5.511   l3.092,13.11c0.01,0.135,0,0.264,0.004,0.399c-6.726,6.03-10.777,14.752-8.636,24.232c4.402,19.498,13.894,27.404,23.238,30.828   c6.199,17.485,12.207,28.533,19.231,35.956c1.773,8.084,3.34,19.076,2.205,29.966c-4.738,45.626-100.744,21.593-100.744,101.706   c0,12.355,41.4,33.902,144.906,33.902c103.506,0,144.906-21.547,144.906-33.902C401.748,329.549,305.742,353.583,301.004,307.957z    M240.039,430.304l-26.276-106.728l32.324,13.453l-1.738,15.619l5.135-0.112L240.039,430.304z M276.209,430.304l-9.447-77.768   l5.135,0.112l-1.738-15.619l32.324-13.453L276.209,430.304z"/>
                                                    </g>
                                                </svg>
                                                <div className={styles.mobileMiddleInfoBlock}>
                                                    <span
                                                        className={styles.mobileMiddleInfo}>{t("Order together")}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<BanerBotton text={"Delivery free"} maxSize={true}/>*/}
                                    </div>
                                ) :
                                (
                                    <>
                                        <div className={styles.leftBlock}>
                                            <div className={styles.leftRow}>
                                                <div className={styles.leftBlockBtn}>
                                                    <InfoButton text={"Ordering paused"} isLink={false}/>
                                                    <InfoButton text={"6.6"} isLink={false}/>
                                                    <InfoButton text={"Limited delivery tracking"} isLink={true}
                                                                handleClick={handleOpenMoreInfoL}/>
                                                    <InfoButton text={"See more information"} isLink={true}
                                                                handleClick={handleOpenMoreInfo}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.rightBlock}>
                                            <InfoButton text={"Order together"} isLink={true}/>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Stores);