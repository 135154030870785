import React from 'react';
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./ModeratorBodyMenuTable.module.scss";
import {styled} from "@mui/material";
import useIsMobile from "../../../../helpers/useIsMobile";
import {t} from "i18next";

const StyledDataGrid = styled(DataGrid)`
    @media (max-width: 640px) {
        font-size: 10px;
        overflow-x: auto;
        .MuiDataGrid-cell{
            padding: 0 3px;
        }
        .MuiDataGrid-columnHeader{
            padding: 0 3px;
        }
        .MuiCheckbox-root {
            padding: 2px;
        }
    }
    .Mui-checked{
        transform: translate(0, 0) !important;
    }
`;
const ProductTable = ({ currentProducts=[], filteredProducts, currentCategoryID, handleSelectionChange,notFound= false }) => {
    const isMobile = useIsMobile();
    let columns = [
        { field: 'ProductName', headerName: t("Product name"), flex: 2, editable: false },
        { field: 'Product_info', headerName: t("Product info"), flex: 3, editable: false },
        { field: 'Description', headerName: t("Description"), sortable: false, flex: 3, editable: false },
        { field: 'Price', headerName: t("Price"), sortable: true, flex: 1, editable: false },
        { field: 'quantity', headerName: t("Quantity"), sortable: true, flex: 1, editable: false },
        { field: 'unit', headerName: t("Unit"), flex: 1, editable: false, maxWidth: 20 },
    ];

    if (isMobile) {
        columns = [
            { field: 'ProductName', headerName: t("Product name"),editable: false, sortable: true, disableColumnMenu: true },
            { field: 'Product_info', headerName: t("Product info"), editable: false, sortable: false, disableColumnMenu: true },
            { field: 'Description', headerName: t("Description"), sortable: false,  editable: false,  disableColumnMenu: true },
            { field: 'Price', headerName: t("Price"), sortable: false,  flex: 1, editable: false, disableColumnMenu: true,filterable: false, },
            { field: 'quantity', headerName: t("Quantity"), sortable: false,  flex: 1, editable: false, disableColumnMenu: true,filterable: false, },
            { field: 'unit', headerName: t("Unit"), flex: 1, editable: false, sortable: false,  disableColumnMenu: true,filterable: false, },
        ];
    }

    return (
        <div className={styles.tableBodyMain}>
            <div className={styles.tableBodyConteiner}>
                <Box sx={{ height: '100%', width: '100%' }}>
                    <StyledDataGrid
                        onRowSelectionModelChange={handleSelectionChange}
                        localeText={{ noRowsLabel: t("Not found products") }}
                        rows={notFound ? [] : (filteredProducts.length || currentCategoryID ? filteredProducts : currentProducts)}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        autoHeight
                    />
                </Box>
            </div>
        </div>
    );
};

export default ProductTable;
