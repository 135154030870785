import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import styles from "./CreateNewShop.module.scss";
import rootStore from "../../store/RootStore";
import {addShop} from "../../api/addContent/addShop";
import {t} from "i18next";

const CreateNewShop = observer(() => {
    const [isSending, setIsSending] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const { setIfOpenCreateShop } = rootStore.moderatorPanelStore;
    const {updateShopsList} = rootStore.superUserStore
    const [shopName, setShopName] = useState('');
    const [address, setAddress] = useState('');
    const [baseDeliveryFee, setBaseDeliveryFee] = useState('');
    const [smallOrderSurchargeLimit, setSmallOrderSurchargeLimit] = useState('');
    const [longDeliverySurchargeLimit, setLongDeliverySurchargeLimit] = useState('');
    const [description, setDescription] = useState('');
    const [estimatedDeliveryTime, setEstimatedDeliverTime] = useState('');
    const [file, setFile] = useState(null);
    const [contactDescription, setContactDescription] = useState('');
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setIfOpenCreateShop(false);
    };

    const validateInputs = () => {
        const newErrors = {};
        if (!shopName.trim()) newErrors.shopName = t("Shop name is required");
        if (!address.trim()) newErrors.address = t("Address is required");
        if (!baseDeliveryFee.trim()|| isNaN(baseDeliveryFee)) newErrors.baseDeliveryFee = t("Base Delivery Fee must be a number");
        if (!smallOrderSurchargeLimit.trim() || isNaN(smallOrderSurchargeLimit)) newErrors.smallOrderSurchargeLimit = t("Small order surcharge limit must be a number");
        if (!longDeliverySurchargeLimit.trim() || isNaN(longDeliverySurchargeLimit)) newErrors.longDeliverySurchargeLimit = t("Long delivery surcharge limit must be a number");
        if (!estimatedDeliveryTime.trim() || isNaN(estimatedDeliveryTime)) newErrors.estimatedDeliveryTime = t("Estimated delivery time must be a number");
        if (!description.trim()) newErrors.description = t("Description is required");
        if (!contactDescription.trim()) newErrors.contactDescription = t("Contact Description info is required");
        if (!file) {
            newErrors.file = t("Image is required");
        } else {
            if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
                newErrors.file = t("File must be an image (jpeg, png, gif)");
            }
            if (file.size > 10 * 1024 * 1024) {
                newErrors.file = t("File size must not exceed 10MB");
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateInputs() ) return;
        const data = new FormData();
        data.append('ShopName', shopName);
        data.append('Address', address);
        data.append('Base_delivery_fee', baseDeliveryFee);
        data.append('Small_order_surcharge_limit', parseFloat(smallOrderSurchargeLimit));
        data.append('Long_delivery_surcharge_limit', longDeliverySurchargeLimit);
        data.append('Description', description);
        data.append('Contact_description', contactDescription);
        data.append('Estimated_delivery_time', estimatedDeliveryTime);
        data.append('ImageUrl', file);
        data.append('AltText', file.name);
        try {
            setIsSending(true);
            const response = await addShop(data);
            if (response.status === 201) {
                setIsSending(false);
                setShowSuccessModal(true);
                setShopName('');
                setAddress('');
                setBaseDeliveryFee('');
                setSmallOrderSurchargeLimit('');
                setLongDeliverySurchargeLimit('');
                setDescription('');
                setContactDescription('');
                setFile(null);
                setTimeout(() => {
                    updateShopsList();
                    setIfOpenCreateShop(false);
                },2000)
            } else {
                setIsSending(false);
                console.error('Error sending data to the server');
            }
        } catch (error) {
            setIsSending(false);
            console.error('Network error:', error);
        }
    };

    return (
        <>
            <Dialog open={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
                <DialogTitle>{t("Success")}</DialogTitle>
                <DialogContent>
                    <p>{t("Shop created successfully!")}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowSuccessModal(false)}>{t("Close")}</Button>
                </DialogActions>
            </Dialog>
            <div className={styles.block1}>
                <div className={styles.block2}>
                    <div className={styles.block3}>
                        <div className={styles.block4}>
                            <div className={styles.block5} data-testid="backButton" onClick={handleClose}>
                                <span className={styles.block6}>
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M8.41421356,13 L13.7071068,18.2928932 L12.2928932,19.7071068 L4.58578644,12 L12.2928932,4.29289322 L13.7071068,5.70710678 L8.41421356,11 L20,11 L20,13 L8.41421356,13 Z" fill="#716E7A"></path></svg>
                                </span>
                            </div>
                            <div className={styles.block7}>
                                <span className={styles.block8}>{t("Shops")}</span>
                                <div className={styles.block9}></div>
                                <span className={styles.block0}>{t("Shop Product")}</span>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.conteiner1}>
                            <div className={styles.conteiner2}>
                                <div className={styles.conteiner3}>
                                    <div className={styles.conteiner4}>
                                        <div className={styles.conteiner5}>
                                            <label className={styles.conteiner6} htmlFor="file">
                                                <input
                                                    id="file"
                                                    type="file"
                                                    className={styles.conteiner7}
                                                    onChange={handleFileChange}
                                                />
                                            </label>
                                            <img className={file ? styles.preview : styles.conteiner8}
                                                 src={file ? URL.createObjectURL(file) : "https://cloud.sigma.ru/svg/restaurant.svg"} alt=""/>

                                        </div>
                                        {errors.file && <span className={styles.error}>{errors.file}</span>}
                                        <div className={styles.conteiner9}>{t("Add image of the Shop")}</div>
                                    </div>
                                    <div>
                                        <div className={styles.main1}>
                                            <div className={styles.main2}>
                                                <div data-label="Shop Name" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input1} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Product name")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="ShopName"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder={t("Enter shop name")}
                                                            data-value=""
                                                            value={shopName}
                                                            onChange={(e) => setShopName(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.shopName &&
                                                        <span className={styles.error}>{errors.shopName}</span>}
                                                </div>
                                                <div data-label="address" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input2} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Address")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="Address"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder={t("Enter address")}
                                                            data-value=""
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.address &&
                                                        <span className={styles.error}>{errors.address}</span>}
                                                </div>
                                                <div data-label="Description" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input6} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Description")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="nameProduct"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder={t("Enter product description")}
                                                            data-value=""
                                                            value={description}
                                                            onChange={(e) => setDescription(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.description &&
                                                        <span className={styles.error}>{errors.description}</span>}
                                                </div>
                                                <div data-label="Contact description" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input7} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="Contact_description">
                                                        {t("Contact description")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="Contact_description"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder={t("Enter contact description")}
                                                            data-value=""
                                                            value={contactDescription}
                                                            onChange={(e) => setContactDescription(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.contactDescription &&
                                                        <span
                                                            className={styles.error}
                                                        >
                                                            {errors.contactDescription}
                                                        </span>}
                                                </div>

                                                <div data-label="baseDeliveryFee" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input3} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Base Delivery Fee")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="baseDeliveryFee"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder="12"
                                                            data-value=""
                                                            value={baseDeliveryFee}
                                                            onChange={(e) => setBaseDeliveryFee(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.baseDeliveryFee &&
                                                        <span className={styles.error}>
                                                            {errors.baseDeliveryFee}
                                                        </span>}
                                                </div>
                                                <div data-label="estimatedDeliveryTime" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input8} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Estimated Delivery Time")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="estimatedDeliveryTime"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder="30"
                                                            data-value=""
                                                            value={estimatedDeliveryTime}
                                                            onChange={(e) => setEstimatedDeliverTime(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.estimatedDeliveryTime &&
                                                        <span className={styles.error}>{errors.estimatedDeliveryTime}</span>}
                                                </div>
                                                <div data-label="smallOrderSurchargeLimit" data-value=""
                                                     data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input4} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Small Surcharge Limit")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="smallOrderSurchargeLimit"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder="5"
                                                            data-value=""
                                                            value={smallOrderSurchargeLimit}
                                                            onChange={(e) => setSmallOrderSurchargeLimit(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.smallOrderSurchargeLimit &&
                                                        <span
                                                            className={styles.error}>
                                                            {errors.smallOrderSurchargeLimit}
                                                        </span>}
                                                </div>
                                                <div data-label="longDeliverySurchargeLimit" data-value=""
                                                     data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input5} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="quantity">
                                                        {t("Long Surcharge Limit")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="quantity"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder="10"
                                                            data-value=""
                                                            value={longDeliverySurchargeLimit}
                                                            onChange={(e) => setLongDeliverySurchargeLimit(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.longDeliverySurchargeLimit &&
                                                        <span className={styles.error}>
                                                            {errors.longDeliverySurchargeLimit}
                                                        </span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.btnBlock}>
                            <button type="submit" className={styles.btnMain} disabled={isSending}>
                                {t("Submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
});

export default CreateNewShop;
